import Main from "common_components/hoc/main.hoc"
import "./admin.screen.scss";
import Assets from "imports/assets.import";
import { useSetState } from "utils/functions.utils";
import { ButtonSize, ButtonTypes, Tabs, Settings } from "utils/interface.utils";
import { Colors, Dropdown, FontFamily, Input, Models, PrimaryButton } from "utils/imports.utils";
import ConfirmationModal from "components/confirmation_modal/confirmation_modal.component";
import RequestHistoryModal from "components/request_history_modal/request_history_modal.component";
import PostedAdCard from "components/posted_ad_card/posted_ad_card.component";
import LineChart from "components/line_chart/line_chart.component";
import DatePickerRange from "common_components/ui/date_picker/date_picker_range.component";
import { useEffect, useRef } from "react";
import { format } from 'date-fns';
import NavBar from "components/nav_bar/nav_bar.component";
import { useLocation } from "react-router-dom";
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css";
import useDebounce from "../../utils/debounce.utils";
import TotalVendors from "components/dashboard_components/totalVendors.component";
import TotalService from "components/dashboard_components/totalService.component";
import TotalSupport from "components/dashboard_components/totalSupport.component";
import TotalAdvertisement from "components/dashboard_components/totalAdvertisement.component";
import { useNavigate } from "react-router-dom";
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
const Admin = (props: any) => {
    const navigate = useNavigate();
    const [state, setState] = useSetState({
        changePasswordActivity:false,
        isProfileSettingsPressed: false,
        isProfileSettingsOpen: false,
        isChangePasswordOpen: false,
        profilePhotoSrc: null,
        currentTab: Tabs.DASHBOARD,
        userSearch: "",
        lineChartData: {
            vendorData: [],
            advertisementData: [],
            serviceData: []
        },
        isConfirmationModalOpen: false,
        isRequestHistoryModalOpen: false,
        isShowDatePickerRangeOpen: false,
        isVendorDetailsOpen: false,
        selectedVendor: null,
        vendorToDelete: null,
        vendorSearch: "",
        getAllVendors: null,
        pickedDateRange: {
            startDate: null,
            endDate: null,
            key: "selection"
        },
        pickedDateRangeFormatted: "",
        getAllUsersService: null,
        selectedUsersService: null,
        supportSearch: "",
        getAllSupportRequest: null,
        // getTotalVendors:null,
        // supportFilter: 'All',
        supportTab: "",
        supportStatus: "",
        oldPassword: "",
        newPassword: "",
        profileData: null,
        rangeVendors: null,
        rangeServices: null,
        rangeAdvertisements: null,
        datePickerVendors: null,
        profile_picture: null
    })
    const fileInputRef = useRef<HTMLInputElement>(null);
    const lineChartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    stepSize: 5, // Adjust this value based on the range and granularity you need
                    maxTicksLimit: 3,
                    font: {
                        family: FontFamily.regular,
                        size: 10,
                        weight: '400',
                        color: '#6B7280',
                    },
                },
                beginAtZero: false,
                max: 750,
                min: 0,
            },
            x: {
                ticks: {
                    font: {
                        family: FontFamily.regular,
                        size: 10,
                        weight: '400',
                        color: '#6B7280',
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Remove the legend
            },
        },
    }
    // Screen Width
    const width = window.innerWidth;

    // To get current route path
    const currentRoute = useLocation();
    const fetchVendors = async (body) => {
        try {
            const response: any = await Models.auth.getAllVendors(body)
            console.log(response.data.docs);
            setState({ getAllVendors: response.data.docs })

        } catch (err) {
            console.log('Failed to fetch vendors', err);
        }
    };
    const vendorDelete = async (body) => {
        try {
            const response: any = await Models.auth.deleteVendor({ vendor_id: body })
            // console.log(response.data);
            // setState({vendorToDelete:response.data})

        } catch (err) {
            console.log('Failed to delete vendor', err);
        }
    };
    const fetchUsersService = async (body) => {
        try {
            const response: any = await Models.auth.getAllUsersService(body)
            console.log(response.data.docs);
            setState({ getAllUsersService: response.data.docs })

        } catch (err) {
            console.log('Failed to fetch users service', err);
        }
    };

    const fetchSupportRequest = async (body) => {
        try {
            const response: any = await Models.auth.getAllSupportRequest(body)
            console.log(response.data.docs);
            setState({ getAllSupportRequest: response.data.docs })

        } catch (err) {
            console.log('Failed to fetch support Requests', err);
        }
    };

    const datePickerVendors = async (body) => {
        try {
            const response: any = await Models.auth.datePickerVendors(body)
            console.log(response);
            setState({ getAllVendors: response })

        } catch (err) {
            console.log('Failed to fetch vendors range in  date picker', err);
        }
    };

    const getProfileData = async () => {
        try {
            const response: any = await Models.auth.getUser()
            console.log("profile data", response.data);
            setState({ profileData: response.data })
            if (response.data.profile_picture) {
                setState({ profilePhotoSrc: response.data.profile_picture })
            } else {
                setState({ profilePhotoSrc: Assets.profile_dummy, })
            }

        } catch (err) {
            console.log('Failed to fetch profile Data', err);
        }
    };

    const rangeAdvertisements = async () => {
        try {
            const response: any = await Models.auth.rangeAdvertisement()
            // console.log("range",response);
            setState({ rangeAdvertisements: response })
            const advertisementData = sort(response.advertisement)
            const service_requests = sort(response.service_requests)
            const vendors = sort(response.vendors)
            setState({ lineChartData: { vendorData: vendors, advertisementData: advertisementData, serviceData: service_requests, } })
            //    console.log(data);
        } catch (err) {
            console.log('Failed to fetch  advertisement range', err);
        }
    };

    const sort = (e) => {
        const monthRanges = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let months = Object.keys(e)
        let year;
        months = months.map(month => {
            const split = month.split(" ")
            year = split[1]
            return split[0]
        })
        console.log(months, year);
        const data: any = []
        monthRanges.forEach(month => {
            if (months.includes(month)) {
                data.push(e[`${month} ${year}`].length)
            } else {
                data.push(0)
            }
        })
        return data
    }
    const debouncedSearch = useDebounce((value) => {
        const body = { search: value.trim() }
        if (state.currentTab === Tabs.VENDORS) {
            fetchVendors(body)
        }
        if (state.currentTab === Tabs.USERS) {
            fetchUsersService(body)
        }
        if (state.currentTab === Tabs.SUPPORT) {
            body["status"] = state.supportTab
            fetchSupportRequest(body)
        }


    }, 500);

    const handleVendorChange = (e) => {
        setState({ vendorSearch: e })
        debouncedSearch(e);
    }
    const handleUserServiceChange = (e) => {
        setState({ userSearch: e })
        debouncedSearch(e);
    }

    const handleSupportTicketsChange = (e) => {
        setState({ supportSearch: e })
        debouncedSearch(e);
    }

    // FUNCTIONS
    const handleDatePickerRange = (ranges: any) => {
        console.log('start,end', ranges)

        const formattedStartDate = format(ranges.startDate, 'dd MMM yyyy');
        const formattedEndDate = format(ranges.endDate, 'dd MMM yyyy');
        setState({
            pickedDateRange: { ranges },
            isShowDatePickerRangeOpen: false,
            pickedDateRangeFormatted: `${formattedStartDate} - ${formattedEndDate}`
        })
        datePickerVendors({ from: formattedStartDate, to: formattedEndDate })
    }

    const handleFilterStatusTab = (event: any) => {
        Array.from(event.currentTarget.children).forEach((ele: any) => {
            ele.classList.remove("active")
            if (ele.textContent === event.target.textContent) {
                event.target.classList.add("active");
                let status = "";
                if (event.target.textContent === "Unreview") {
                    status = "Under review"
                }
                if (event.target.textContent === "Resolved") {
                    status = "Resolved"
                }
                if (event.target.textContent === "Closed") {
                    status = "Closed"
                }
                console.log(event.target.textContent);
                fetchSupportRequest({ status })
                setState({ supportTab: status })
            }
        })
    }

    const handleProfileSettingClick = () => {
        setState({
            isProfileSettingsPressed: !state.isProfileSettingsPressed,
        })
    }

    // const handleProfileSettingsUpdate = () => {
    //     setState({
    //         isProfileSettingsOpen:false,
    //     })
    // }

    const handlePasswordChangeUpdate = async () => {
        try {
            setState({
                isPasswordChangeOpen: false,
                changePasswordActivity:true
            })
            const response = await Models.auth.changePassword({
                old_password: state.oldPassword,
                password: state.newPassword,
            })
            setState({ oldPassword: "", newPassword: "", changePasswordActivity:false })
            alert("password changed sucessfull")
        } catch (error) {
            console.error('Password change failed:', error);
            setState({changePasswordActivity:false})
        }
    }

    const handleProfileSettingItemsClick = (e: any) => {
        switch (e.target.textContent) {
            case Settings.PROFILE_SETTINGS:
                setState({
                    isProfileSettingsOpen: true,
                    isChangePasswordOpen: false,
                    isProfileSettingsPressed: !state.isProfileSettingsPressed,
                    currentTab: Tabs.USERS,
                })
                break;
            case Settings.CHANGE_PASSWORD:
                setState({
                    isProfileSettingsOpen: false,
                    isChangePasswordOpen: true,
                    isProfileSettingsPressed: !state.isProfileSettingsPressed,
                    currentTab: Tabs.USERS,
                })
                break;
            case Settings.SUPPORT:
                setState({
                    isProfileSettingsOpen: false,
                    isChangePasswordOpen: false,
                    isProfileSettingsPressed: !state.isProfileSettingsPressed,
                    currentTab: Tabs.SUPPORT,
                })
                break;
            case Settings.LOGOUT:
                // LOGOUT
                break;
            default:
                return null;
        }
    }

    const handlePhotoUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate("/admin/login")
    }


    const handleProfileSettingsUpdate = async (body) => {
        setState({
            isProfileSettingsOpen: false,
        })
        try {
            const formData = new FormData();
            formData.append("username", state.profileData.username)
            formData.append("email", state.profileData.email)
            if (state.profile_picture) {
                formData.append("profile_picture", state.profile_picture)
            }
            const response: any = await Models.auth.editUser(formData)
            setState({ profileData: response.data })
            if (response) {
                console.log('User updated successful:', response);
            }

        } catch (error) {
            console.error('User updated failed:', error);

        }
    }

    useEffect(() => {
        let token = localStorage.getItem("token")
        if (token) {
            fetchVendors({});
            fetchUsersService({});
            fetchSupportRequest({});
            getProfileData();
            rangeAdvertisements();
        } else {
            navigate("/admin/login")
        }
    }, []);
    useEffect(() => {
        const isAdminScreen = location.pathname.startsWith('/admin');
        document.body.style.overflow = isAdminScreen ? 'hidden' : 'visible';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <Main className="admin">
            <NavBar onClick={handleProfileSettingClick} profileData={state.profileData} />
            <div className="admin_container">
                <div className="admin_side_bar">
                    <div onClick={() => setState({ currentTab: Tabs.DASHBOARD, isProfileSettingsOpen: false, isChangePasswordOpen: false })} className={state.currentTab === Tabs.DASHBOARD ? "admin_side_bar_item admin_side_bar_item_active" : "admin_side_bar_item"}>
                        <div className="admin_side_bar_item_icon_container">
                            <img src={state.currentTab === Tabs.DASHBOARD ? Assets.dashboardActive : Assets.dashboardInactive} className="admin_side_bar_item_icon" alt="Dashboard Icon" />
                        </div>
                        <div>
                            <div className="admin_side_bar_item_title">Dashboard</div>
                        </div>
                    </div>
                    <div onClick={() => setState({ currentTab: Tabs.USERS, isProfileSettingsOpen: false, isChangePasswordOpen: false })} className={state.currentTab === Tabs.USERS ? "admin_side_bar_item admin_side_bar_item_active" : "admin_side_bar_item"}>
                        <div className="admin_side_bar_item_icon_container">
                            <img src={state.currentTab === Tabs.USERS ? Assets.usersActive : Assets.usersInactive} className="admin_side_bar_item_icon" alt="Users Icon" />
                        </div>
                        <div>
                            <div className="admin_side_bar_item_title">Users</div>
                        </div>
                    </div>
                    <div onClick={() => setState({ currentTab: Tabs.VENDORS, isProfileSettingsOpen: false, isChangePasswordOpen: false })} className={state.currentTab === Tabs.VENDORS ? "admin_side_bar_item admin_side_bar_item_active" : "admin_side_bar_item"}>
                        <div className="admin_side_bar_item_icon_container">
                            <img src={state.currentTab === Tabs.VENDORS ? Assets.vendorsActive : Assets.vendorsInactive} className="admin_side_bar_item_icon" alt="Vendors Icon" />
                        </div>
                        <div>
                            <div className="admin_side_bar_item_title">Vendors</div>
                        </div>
                    </div>
                    <div onClick={() => setState({ currentTab: Tabs.SUPPORT, isProfileSettingsOpen: false, isChangePasswordOpen: false })} className={state.currentTab === Tabs.SUPPORT ? "admin_side_bar_item admin_side_bar_item_active" : "admin_side_bar_item"}>
                        <div className="admin_side_bar_item_icon_container">
                            <img src={state.currentTab === Tabs.SUPPORT ? Assets.supportActive : Assets.supportInactive} className="admin_side_bar_item_icon" alt="Support Icon" />
                        </div>
                        <div>
                            <div className="admin_side_bar_item_title">Support</div>
                        </div>
                    </div>
                </div>
                <div className="admin_main">
                    {
                        state.currentTab === Tabs.DASHBOARD &&
                        <div className="admin_dashboard">
                            <div className="admin_dashboard_container">
                                <div className="admin_dashboard_header">
                                    <div className="admin_dashboard_header_title">Dashboard</div>
                                </div>
                                <div className="admin_dashboard_summary">
                                    <div className="admin_dashboard_summary_item">
                                        <div className="admin_dashboard_summary_item_icon_container">
                                            <img src={Assets.user_circle} alt="User icon" />
                                        </div>
                                        <div>
                                            <TotalService />
                                        </div>
                                    </div>
                                    <div className="admin_dashboard_summary_item">
                                        <div className="admin_dashboard_summary_item_icon_container">
                                            <img src={Assets.user_circle} alt="User icon" />
                                        </div>
                                        <div>
                                            <TotalAdvertisement />
                                        </div>
                                    </div>
                                    <div className="admin_dashboard_summary_item">
                                        <div className="admin_dashboard_summary_item_icon_container">
                                            <img src={Assets.user_circle} alt="User icon" />
                                        </div>
                                        <div>
                                            <TotalVendors />
                                        </div>
                                    </div>
                                </div>
                                <div className="admin_dashboard_queries">
                                    <div className="admin_dashboard_queries_container">
                                        <div className="admin_dashboard_queries_header">
                                            <div className="admin_dashboard_queries_header_icon_container">
                                                <img src={Assets.query_icon} alt="Queries Icon" />
                                            </div>
                                            <div className="admin_dashboard_queries_header_title">
                                                Queries
                                            </div>
                                        </div>
                                        <TotalSupport />
                                    </div>
                                </div>
                                <div className="admin_dashboard_line_chart_container">
                                    <div className="admin_dashboard_line_chart_header">
                                        <div className="admin_dashboard_line_chart_header_title">User analytics</div>
                                        <div className="admin_dashboard_line_chart_legend">
                                            <div className="admin_dashboard_line_chart_legend_item">
                                                <div className="admin_dashboard_line_chart_legend_color_container">
                                                    <img src={Assets.chart_yellow} alt="Line Chart Dataset Color Yellow" />
                                                </div>
                                                <div className="admin_dashboard_line_chart_legend_label">Total request</div>
                                            </div>
                                            <div className="admin_dashboard_line_chart_legend_item">
                                                <div className="admin_dashboard_line_chart_legend_color_container">
                                                    <img src={Assets.chart_red} alt="Line Chart Dataset Color red" />
                                                </div>
                                                <div className="admin_dashboard_line_chart_legend_label">Total advertisement</div>
                                            </div>
                                            <div className="admin_dashboard_line_chart_legend_item">
                                                <div className="admin_dashboard_line_chart_legend_color_container">
                                                    <img src={Assets.chart_blue} alt="Line Chart Dataset Color blue" />
                                                </div>
                                                <div className="admin_dashboard_line_chart_legend_label">Total vendor</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: width < 1450 ? "96%" : "98%" }}>
                                        <LineChart data={{
                                            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                            datasets: [
                                                {
                                                    label: 'Total request',
                                                    data: state.lineChartData.serviceData, // Example data, replace with actual data
                                                    borderColor: '#FFD03B',
                                                    fill: false,
                                                    tension: 0.4,
                                                    pointRadius: 0,
                                                },
                                                {
                                                    label: 'Total advertisement',
                                                    data: state.lineChartData.advertisementData, // Example data, replace with actual data
                                                    borderColor: '#FF5C60',
                                                    fill: false,
                                                    tension: 0.4,
                                                    pointRadius: 0,
                                                },
                                                {
                                                    label: 'Total vendor',
                                                    data: state.lineChartData.vendorData, // Example data, replace with actual data
                                                    borderColor: '#000BFB',
                                                    fill: false,
                                                    tension: 0.4,
                                                    pointRadius: 0,
                                                },
                                            ]
                                        }} options={lineChartOptions} height={width < 1450 ? 260 : 350} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        state.currentTab === Tabs.USERS && !state.isProfileSettingsOpen && !state.isChangePasswordOpen &&
                        <div className="admin_users">
                            <div className="admin_users_container">
                                <div className="admin_users_header">
                                    <div className="admin_users_header_title">Users</div>
                                    <div className="admin_users_header_search_bar_container">
                                        <Input
                                            icon={Assets.search}
                                            iconPosition="start"
                                            placeholder="Search here..."
                                            value={state.userSearch}
                                            onChange={(e) => handleUserServiceChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="admin_users_table">
                                    <div className="admin_users_table_container">
                                        <div className="admin_users_table_header">
                                            <div className="admin_users_table_header_label">User id</div>
                                            <div className="admin_users_table_header_label">Mobile no</div>
                                            <div className="admin_users_table_header_label">Location</div>
                                            <div className="admin_users_table_header_label">Price</div>
                                            <div className="admin_users_table_header_label">City</div>
                                            <div className="admin_users_table_header_label">History</div>
                                        </div>
                                    </div>
                                    <div className="admin_users_table_body">
                                        {state?.getAllUsersService?.map((data: any, index) => ( // {state.dummyGetAllUsers.map((data:any,index)  , data.id, phono_no, address,price,total_request
                                            <div className="admin_users_table_body_item">
                                                <div className="admin_users_table_body_item_data">{data?._id}</div>
                                                <div className="admin_users_table_body_item_data">{data?.phone_number}</div>
                                                <div className="admin_users_table_body_item_data">{data?.location[0]} , {data.location[1]}</div>
                                                <div className="admin_users_table_body_item_data">{data?.min_price} - {data.max_price}</div>
                                                <div className="admin_users_table_body_item_data">{data?.address}</div>
                                                <div className="admin_users_table_body_item_history_img_container" onClick={() => setState({ isRequestHistoryModalOpen: true, selectedUsersService: data })}>
                                                    <img src={Assets.eye} alt="Clickable Image to View User Request History" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {state.isRequestHistoryModalOpen &&
                                    <RequestHistoryModal
                                        // requestId={"1234"}
                                        // userMobile={"7871733243"}
                                        // userLocation={"Perambur, Chennai"}
                                        // price={"20000"}
                                        // userMailId={"subramani@brownbutton.io"}
                                        // vendorDetails={state.dummyVendorsSentDetails}
                                        // closeModal={() => setState({isRequestHistoryModalOpen:false})}
                                        requestId={state.selectedUsersService._id}
                                        userMobile={state.selectedUsersService.phone_number}
                                        userLocation={`${state.selectedUsersService.location[0]} , ${state.selectedUsersService.location[1]}`}
                                        price={`${state.selectedUsersService.min_price} - ${state.selectedUsersService.max_price}`}
                                        userMailId={""}
                                        vendorDetails={state.selectedUsersService.vendor_list}
                                        closeModal={() => setState({ isRequestHistoryModalOpen: false })}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {
                        state.isProfileSettingsOpen &&
                        <div className="admin_profile">
                            <div className="admin_profile_container">
                                <div className="admin_profile_header">
                                    <div className="admin_profile_header_img_container">
                                        <img src={Assets.caret_left_black_small} alt="Press to go back" onClick={() => setState({ isProfileSettingsOpen: false })} />
                                    </div>
                                    <div className="admin_profile_header_title">Profile settings</div>
                                </div>
                                <div className="admin_profile_update_container">
                                    <div className="admin_profile_update_photo_container">
                                        <div className="admin_profile_update_photo_img_container">
                                            <img src={state.profilePhotoSrc} alt="Admin profile photo image" />
                                        </div>
                                        <div>
                                            <div className="admin_profile_update_photo_title">Set your profile image</div>
                                            <div className="admin_profile_update_photo_file_button" onClick={handlePhotoUpload}>
                                                <img className="admin_profile_update_photo_file_icon" src={Assets.upload} />
                                                <div className="admin_profile_update_photo_file_title">Upload</div>
                                            </div>
                                            <Input
                                                refElement={fileInputRef}
                                                icon={Assets.upload}
                                                placeholder="Upload"
                                                type="file"
                                                iconPosition="start"
                                                className="admin_profile_update_photo_upload_input"
                                                onChange={(e) => {
                                                    const files = e.target.files;
                                                    if (files && files.length > 0) {
                                                        const file = files[0];
                                                        setState({ profile_picture: file })
                                                        const fileReader = new FileReader();
                                                        fileReader.onloadend = () => {
                                                            setState({
                                                                profilePhotoSrc: fileReader.result as string,
                                                            })
                                                        }
                                                        fileReader.readAsDataURL(file);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="admin_profile_update_details_container">
                                        <div className="admin_profile_update_details_input_container">
                                            <Input
                                                label="Name"
                                                onChange={(e) => setState({ profileData: { ...state.profileData, username: e.target.value } })}
                                                type="text"
                                                value={state?.profileData?.username} //value={"subramani"} // value={state?.profileData?.username}
                                            />
                                        </div>
                                        <div className="admin_profile_update_details_input_container">
                                            <Input
                                                label="Email id"
                                                onChange={(e) => setState({ profileData: { ...state.profileData, email: e.target.value } })}
                                                type="text"
                                                value={state?.profileData?.email}// value={"subramani@brownbutton.io"}
                                            />
                                        </div>
                                    </div>
                                    <div className="admin_profile_update_button_container">
                                        <PrimaryButton
                                            text="Update"
                                            onClick={() => handleProfileSettingsUpdate(state.profileData)}
                                            fontFamily={FontFamily.medium}
                                            type={ButtonTypes.PRIMARY}
                                            size={ButtonSize.SMALL}
                                            style={{ fontSize: "14px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        state.isChangePasswordOpen &&
                        <div className="admin_change_password">
                            <div className="admin_change_password_container">
                                <div className="admin_change_password_header">
                                    <div className="admin_change_password_header_img_container">
                                        <img src={Assets.caret_left_black_small} alt="Press to go back" onClick={() => setState({ isChangePasswordOpen: false })} />
                                    </div>
                                    <div className="admin_change_password_header_title">Change password</div>
                                </div>
                                <div className="admin_change_password_details_container">
                                    <div className="admin_change_password_details_input_container">
                                        <Input
                                            label="Old password"
                                            placeholder="Old password"
                                            onChange={(e) => setState({ oldPassword: e.target.value })}
                                            value={state.oldPassword}
                                        />
                                    </div>
                                    <div className="admin_change_password_details_input_container">
                                        <Input
                                            label="New password"
                                            placeholder="New password"
                                            onChange={(e) => setState({ newPassword: e.target.value })}
                                            value={state.newPassword}
                                        />
                                    </div>
                                </div>
                                <div className="admin_change_password_button_container">
                                    <PrimaryButton
                                        activity={state.changePasswordActivity}
                                        onClick={handlePasswordChangeUpdate}
                                        text="Change password"
                                        size={ButtonSize.SMALL}
                                        type={ButtonTypes.PRIMARY}
                                        fontFamily={FontFamily.medium}
                                        style={{ fontSize: "14px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        state.currentTab === Tabs.VENDORS && !state.isVendorDetailsOpen &&
                        <div className="admin_vendors">
                            <div className="admin_vendors_container">
                                <div className="admin_vendors_header">
                                    <div className="admin_vendors_header_title">Vendors</div>
                                    <div className="admin_vendors_header_filter_container">
                                        <div className="admin_vendors_header_search_bar_container">
                                            <Input
                                                icon={Assets.search_gray}
                                                iconPosition={"start"}
                                                placeholder="Search here..."
                                                value={state.vendorSearch}
                                                onChange={(e) => handleVendorChange(e.target.value)}
                                            />
                                        </div>
                                        <div className="admin_vendors_header_date_picker_container">
                                            <Input
                                                icon={Assets.calendar_gray}
                                                iconPosition="start"
                                                placeholder="Select date range..."
                                                onClick={() => setState({ isShowDatePickerRangeOpen: true })}
                                                value={state.pickedDateRangeFormatted}
                                                readOnly
                                            />
                                            {
                                                state.isShowDatePickerRangeOpen && (
                                                    <div className="admin_vendors_header_date_range_picker_modal">
                                                        <DatePickerRange
                                                            handleSave={(ranges: any) => handleDatePickerRange(ranges)}
                                                            months={1}
                                                            direction="horizontal"
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="admin_vendors_table">
                                    <div className="admin_vendors_table_container">
                                        <div className="admin_vendors_table_header">
                                            <div className="admin_vendors_table_header_label">Vendor id</div>
                                            <div className="admin_vendors_table_header_label">Vendor name</div>
                                            <div className="admin_vendors_table_header_label">Total requests</div>
                                            <div className="admin_vendors_table_header_label">Accepted request</div>
                                            <div className="admin_vendors_table_header_label">Action</div>
                                        </div>
                                        <div className="admin_vendors_table_body">
                                            {state?.getAllVendors?.map((data: any, index) => (
                                                <div className="admin_vendors_table_body_item">
                                                    <div onClick={() => setState({ isVendorDetailsOpen: true, selectedVendor: data })} className="admin_vendors_table_body_item_data">{data._id}</div>
                                                    <div onClick={() => setState({ isVendorDetailsOpen: true, selectedVendor: data })} className="admin_vendors_table_body_item_data">{data.name}</div>
                                                    <div onClick={() => setState({ isVendorDetailsOpen: true, selectedVendor: data })} className="admin_vendors_table_body_item_data">{data.total_requests}</div>
                                                    <div onClick={() => setState({ isVendorDetailsOpen: true, selectedVendor: data })} className="admin_vendors_table_body_item_data">{data.accepted_requests}</div>
                                                    <div className="admin_vendors_table_body_item_delete_icon_container" onClick={() => setState({ isConfirmationModalOpen: true, vendorToDelete: data._id })}>
                                                        <img src={Assets.trash_bin} alt="Clickable Icon to Delete the Vendor" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        state.currentTab === Tabs.VENDORS && state.isVendorDetailsOpen &&
                        <div className="admin_vendor_details">
                            <div className="admin_vendor_details_container">
                                <div className="admin_vendor_details_header">
                                    <div className="admin_vendor_details_header_icon_container" onClick={() => setState({ isVendorDetailsOpen: false })}>
                                        <img src={Assets.caret_left_black} alt="Go Back to Vendors Details Table" />
                                    </div>
                                    <div className="admin_vendor_details_header_title">
                                        Vendor Details
                                    </div>
                                </div>
                                <div className="admin_vendor_details_data">
                                    <div className="admin_vendor_details_data_container">
                                        <div>
                                            <div className="admin_vendor_details_data_title">Vendor details</div>
                                            <div className="admin_vendor_details_data_items_container">
                                                <div>
                                                    <div className="admin_vendor_details_data_item_label">Vendor name</div>
                                                    <div className="admin_vendor_details_data_item_value">{state.selectedVendor.name}</div>
                                                </div>
                                                <div>
                                                    <div className="admin_vendor_details_data_item_label">Email id</div>
                                                    <div className="admin_vendor_details_data_item_value">{state.selectedVendor.email}</div>
                                                </div>
                                                <div>
                                                    <div className="admin_vendor_details_data_item_label">Phone no</div>
                                                    <div className="admin_vendor_details_data_item_value">{state.selectedVendor.phone_number}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="admin_vendor_details_data_button_container">
                                            <PrimaryButton
                                                onClick={() => setState({
                                                    isConfirmationModalOpen: true,
                                                    vendorToDelete: state.selectedVendor._id,
                                                    isVendorDetailsOpen: false,
                                                })}
                                                type={ButtonTypes.OUTLINED}
                                                size={ButtonSize.SMALL}
                                                text="Delete"
                                                fontFamily={FontFamily.semiBold}
                                                className={"admin_vendor_details_data_button_delete"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="admin_vendor_details_ad">
                                    <div className="admin_vendor_details_ad_container">
                                        <div className="admin_vendor_details_ad_header">
                                            <div className="admin_vendor_details_ad_header_title">Post Advertisement</div>
                                        </div>
                                        <div className="admin_vendor_details_ad_list_container">
                                            {state.selectedVendor.advertisements.map((data: any, index: any) => (
                                                <div className="admin_vendor_details_ad_list_item">
                                                    <PostedAdCard
                                                        title={data.title}
                                                        description={data.description}
                                                        date={formatDate(data.created_at)}
                                                        photos={data.images}
                                                        price={data.max_price}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        state.currentTab === Tabs.SUPPORT &&
                        <div className="admin_support">
                            <div className="admin_support_container">
                                <div className="admin_support_header">
                                    <div>
                                        <div className="admin_support_header_title">
                                            Support request
                                        </div>
                                    </div>
                                    <div className="admin_support_header_search_bar_container">
                                        <Input
                                            icon={Assets.search_gray}
                                            iconPosition={"start"}
                                            placeholder="Search here..."
                                            value={state.supportSearch}
                                            onChange={(e) => handleSupportTicketsChange(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="admin_support_table">
                                    <div className="admin_support_table_status_bar" onClick={handleFilterStatusTab}>
                                        <div className="admin_support_table_status_bar_label active">All complaints</div>
                                        <div className="admin_support_table_status_bar_label">Unreview</div>
                                        <div className="admin_support_table_status_bar_label">Resolved</div>
                                        <div className="admin_support_table_status_bar_label">Closed</div>
                                    </div>
                                    <div className="admin_support_table_container">
                                        <div className="admin_support_table_header">
                                            <div className="admin_support_table_header_label">Request id</div>
                                            <div className="admin_support_table_header_label">User mail id</div>
                                            <div className="admin_support_table_header_label">Vendor name</div>
                                            <div className="admin_support_table_header_label">Vendor phone no</div>
                                            <div className="admin_support_table_header_label">Status</div>
                                        </div>
                                    </div>
                                    <div className="admin_support_table_body">
                                        {state?.getAllSupportRequest?.map((data: any, index) => (
                                            <div className="admin_support_table_body_item" key={data._id}>
                                                <div className="admin_support_table_body_item_data">{data?.requestId}</div>
                                                <div className="admin_support_table_body_item_data">{data?.user_email}</div>
                                                <div className="admin_support_table_body_item_data">{data?.vendor?.name}</div>
                                                <div className="admin_support_table_body_item_data">{data?.vendor_phone_number}</div>
                                                <div>
                                                    <Dropdown
                                                        data={[{ label: "Under review", value: "Under review" }, { label: "Resolved", value: "Resolved" }, { label: "Closed", value: "Closed" }]}
                                                        value={{ label: data.status, value: data.status }}
                                                        id={data._id}
                                                        className={
                                                            data.status === "Under review" ? "drop_down_under_review" :
                                                                data.status === "Resolved" ? "drop_down_resolved" :
                                                                    data.status === "Closed" ? "drop_down_closed" : ""
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* CONFIRMATION MODAL */}
                    {
                        state.isConfirmationModalOpen &&
                        <ConfirmationModal
                            primaryText="Delete"
                            secondaryText="Are you sure, you want to delete this vendor?"
                            acceptBtnText="Delete"
                            acceptHandle={() => {
                                // console.log(state.vendorToDelete);
                                vendorDelete(state.vendorToDelete)
                                setState({
                                    getAllVendors: state.getAllVendors.filter((vendor) => vendor._id !== state.vendorToDelete),
                                    isConfirmationModalOpen: false,
                                    vendorToDelete: null,
                                })
                            }}
                            rejectBtnText="Cancel"
                            rejectHandle={() => {
                                setState({ isConfirmationModalOpen: false, vendorToDelete: null })
                            }}
                            closeModal={() =>
                                setState({ isConfirmationModalOpen: false, vendorToDelete: null })}
                        />
                    }
                    {
                        state.isProfileSettingsPressed &&
                        <div className="admin_profile_modal">
                            <div className="admin_profile_modal_container" onClick={handleProfileSettingItemsClick}>
                                <div className="admin_profile_modal_item">
                                    <div className="admin_profile_modal_item_img_container">
                                        <img className="admin_profile_modal_item_img" src={Assets.user} alt="User Logo" />
                                    </div>
                                    <div className="admin_profile_modal_item_title">Profile settings</div>
                                </div>
                                <div className="admin_profile_modal_item">
                                    <div className="admin_profile_modal_item_img_container">
                                        <img className="admin_profile_modal_item_img" src={Assets.lock} alt="User Logo" />
                                    </div>
                                    <div className="admin_profile_modal_item_title">Change password</div>
                                </div>
                                <div className="admin_profile_modal_item">
                                    <div className="admin_profile_modal_item_img_container">
                                        <img className="admin_profile_modal_item_img" src={Assets.support} alt="User Logo" />
                                    </div>
                                    <div className="admin_profile_modal_item_title">Support</div>
                                </div>
                                <div className="admin_profile_modal_item">
                                    <div className="admin_profile_modal_item_img_container">
                                        <img className="admin_profile_modal_item_img" src={Assets.logout} alt="User Logo" />
                                    </div>
                                    <div className="admin_profile_modal_item_title" onClick={handleLogout}>Logout</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Main>
    )
}

export default Admin;
import React from "react";
import { useSetState } from "utils/functions.utils";
import { Assets, Colors, FontFamily, PrimaryButton } from "utils/imports.utils";
import "./header.component.scss";
import { ButtonSize, ButtonTypes, IObject } from "utils/interface.utils";
import { useNavigate } from 'react-router-dom';

interface IHeaderComponent {
  primaryButton?: IObject;
  onClick?: Function;
}

const HeaderComponent = (props: IHeaderComponent) => {
  const navigate = useNavigate();

  const [state, setState] = useSetState({
    isMenuOpen: false,
  });

  return (
    <>
      <div className="header">
        <div className="header_container">
          <div className="header_logo_container" onClick={() => navigate("/")}>
            <img src={Assets.logo} className="header_logo_image" alt="logo" />
            <div className="header_name_container">
              <div className="header_name">QuickServe</div>
            </div>
          </div>
          <div className="header_button_container">
              <div className="header_button_support">
                <PrimaryButton
                  left_icon={Assets.headset}
                  text={"Support"}
                  size={ButtonSize.SMALL}
                  fontFamily={FontFamily.medium}
                  type={ButtonTypes.OUTLINED}
                  onClick={() => navigate("/support")} 
                />
              </div>
              <div className="header_button">
                    <PrimaryButton
                      color={Colors.primaryWhite}
                      type={ButtonTypes.SECONDARY}
                      text={"Become a Vendor"}
                      size={ButtonSize.SMALL}
                      fontFamily={FontFamily.semiBold}
                      onClick={() => navigate('/vendor')}
                    />
              </div>
          </div>
          <div className="header_mobile_menu">
            <div onClick={(e:any) => setState({isMenuOpen:!state.isMenuOpen}) }>
              {
                !state.isMenuOpen ?
                  <div className="header_mobile_menu_icon_container">
                    <img src={Assets.hamburger} alt="Icon for opening the menu in mobile screens" />
                  </div> :
                  <div className="header_mobile_menu_icon_container">
                    <img src={Assets.nav_bar_close} alt="Icon for closing the menu in mobile screens" />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>

      {
            state.isMenuOpen  && 
            <div className="header_mobile_menu_body">
              <div className="header_mobile_menu_body_container">
                <div className="header_mobile_menu_button_container">
                  <div className="header_mobile_menu_button_support">
                    <PrimaryButton
                      left_icon={Assets.headset}
                      text={"Support"}
                      size={ButtonSize.SMALL}
                      fontFamily={FontFamily.medium}
                      type={ButtonTypes.OUTLINED}
                      onClick={() => {setState({isMenuOpen:!state.isMenuOpen});navigate("/support")}}
                    />
                  </div>
                  <div className="header_mobile_menu_button">
                    <PrimaryButton
                      color={Colors.primaryWhite}
                      type={ButtonTypes.SECONDARY}
                      text={"Become a Vendor"}
                      size={ButtonSize.SMALL}
                      fontFamily={FontFamily.semiBold}
                      onClick={() => {setState({isMenuOpen:!state.isMenuOpen});navigate('/vendor')}}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
    </>
  );
};

export default HeaderComponent;

import logo from 'assets/icons/logo.svg';
import caret_down from 'assets/icons/caret-down.svg';
import close_black from 'assets/icons/close_black.svg';
import map_pin from 'assets/icons/map_pin.svg';
import info from 'assets/icons/info.svg';
import success_illustration from "assets/images/success-illustration.webp";
import vendor_illustration from "assets/images/vendor-illustration.webp";
import download from "assets/icons/download.svg";
import how_to_install_illustration from "assets/images/how_to_install_illustration.webp";
import dialing_code from "assets/icons/dialing_code.svg";
import headset from "assets/icons/headset.svg"
import caret_left from "assets/icons/caret-left.svg";
import eye from "assets/icons/eye.svg";
import eye_slash from "assets/icons/eye-slash.svg";
import dummy_profile from "assets/icons/dummy_profile.svg";
import usersActive from "assets/icons/users-active.svg";
import usersInactive from "assets/icons/users-inactive.svg";
import vendorsActive from "assets/icons/vendors-active.svg";
import vendorsInactive from "assets/icons/vendors-inactive.svg";
import dashboardActive from "assets/icons/dashboard-active.svg";
import dashboardInactive from "assets/icons/dashboard-inactive.svg";
import supportActive from "assets/icons/support-active.svg";
import supportInactive from "assets/icons/support-inactive.svg";
import dummyAdImage from "assets/images/dummy_ad_image.webp";
import location_pin from "assets/icons/location_pin.svg";
import calendar from "assets/icons/calendar.svg";
import user_circle from "assets/icons/user_circle.svg";
import query_icon from "assets/icons/query_icon.svg";
import search from "assets/icons/search.svg";
import chart_yellow from "assets/icons/chart_yellow.svg";
import chart_red from "assets/icons/chart_red.svg";
import chart_blue from "assets/icons/chart_blue.svg";
import trash_bin from "assets/icons/trash_bin.svg";
import search_gray from "assets/icons/search_gray.svg";
import caret_left_black from "assets/icons/caret_left_black.svg"
import calendar_gray from "assets/icons/calendar-gray.svg";
import gps_fix from "assets/icons/gps_fix.svg";
import map_pin_area from "assets/icons/map_pin_area.svg";
import caret_down_red from "assets/icons/caret_down_red.svg";
import caret_down_green from "assets/icons/caret_down_green.svg";
import caret_down_gray from "assets/icons/caret_down_gray.svg";
import user from "assets/icons/user.svg";
import lock from "assets/icons/lock.svg";
import support from "assets/icons/headset_gray.svg";
import logout from "assets/icons/logout.svg"
import caret_left_black_small from "assets/icons/caret_left_black_small.svg"
import profile_dummy from "assets/images/profile_image_dummy.png";
import upload from "assets/icons/upload.svg";
import loader_1 from "assets/videos/loader_1.gif";
import activity from "assets/videos/activity.gif";
import hamburger from "assets/icons/hamburger.svg";
import nav_bar_close from "assets/icons/nav-bar-close.svg";
const Assets = {
  activity,
  calendar,
  calendar_gray,
  caret_down,
  caret_down_gray,
  caret_down_green,
  caret_down_red,
  caret_left,
  caret_left_black,
  caret_left_black_small,
  chart_blue,
  chart_red,
  chart_yellow,
  close_black,
  dashboardActive,
  dashboardInactive,
  dialing_code,
  download,
  dummyAdImage,
  dummy_profile,
  eye,
  eye_slash,
  gps_fix,
  hamburger,
  headset,
  how_to_install_illustration,
  info,
  loader_1,
  location_pin,
  logo,
  logout,
  map_pin,
  map_pin_area,
  nav_bar_close,
  profile_dummy,
  query_icon,
  search,
  search_gray,
  success_illustration,
  supportActive,
  supportInactive,
  vendor_illustration,
  vendorsActive,
  vendorsInactive,
  trash_bin,
  usersActive,
  user_circle,
  usersInactive,
  user,
  lock,
  support,
  upload
};

export default Assets;
